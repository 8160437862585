import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import loadable from '@loadable/component'
import React, {useRef, useState} from 'react'

import {aboutUsMainPropTypes} from 'helpers/propTypes'
import {slugTransformer} from 'helpers/utils'
import BoxLayout from 'components/UI/Layouts/BoxLayout'
import ContentSection from 'components/UI/ContentSection'
import Hero from 'components/UI/Hero'
import KpisList from 'components/AboutUs/Home/Kpis'
import Media from 'components/UI/Media'
import PillsList from 'components/UI/Pills'
import Section from 'components/UI/Section'
import SEO from 'components/seo'

const AboutUs = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))
  const [videoIsPlaying, setVideoIsPlaying] = useState(false)

  const videoRef = useRef(null)

  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setVideoIsPlaying(true)
    }
  }

  const {
    imageOrVideo,
    metaTitle,
    metaDescription,
    pageTitle,
    surtitle,
    callToAction,
    kpi1Number,
    kpi1ShortDescription,
    kpi1Icon,
    kpi2Number,
    kpi2ShortDescription,
    kpi2Icon,
    kpi3Number,
    kpi3ShortDescription,
    kpi3Icon,
    kpi4Number,
    kpi4ShortDescription,
    kpi4Icon,
    media,
    thumbnail,
    pillarsItem1,
    pillarsItem2,
    pillarsItem3,
    pillarsItem4,
    pillarsItem5,
    pillarsTitle,
    contentSections1,
    contentSections2,
    contentSections3,
  } = R.pathOr(null, ['contentfulAboutUs'], data)

  const shortDescription = R.pathOr(
    ' ',
    ['contentfulAboutUs', 'shortDescription', 'shortDescription'],
    data,
  )

  const slug = R.pathOr(
    ' ',
    ['contentfulAboutUs', 'callToActionLinkedPage', 'slug'],
    data,
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  const heroSlug = slugTransformer(slug)

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div className="decorationWrapper">
        <Decorations>
          <Decoration webSiteName="keyrus" color="blue" top={52} />
          <Decoration webSiteName="keyrus" color="orange" right={0} top={60} />
          <Decoration
            webSiteName="keyrus"
            color="red"
            right={0}
            bottom={-160}
          />
          <Decoration webSiteName="kls" svg="left" left={-80} top={0} />
          <Decoration webSiteName="kls" svg="right" right={0} top={34} />
        </Decorations>
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          {pageTitle && (
            <Hero
              hat={surtitle}
              title={pageTitle}
              titleType="title"
              description={shortDescription}
              descriptionType="description"
              media={imageOrVideo}
              ctaTitle={callToAction}
              ctaLink={heroSlug}
              hasAutoWidth
            />
          )}
        </Section>
      </div>
      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        {kpi1Number && (
          <KpisList
            kpi1Number={kpi1Number}
            kpi1ShortDescription={kpi1ShortDescription}
            kpi1Icon={kpi1Icon}
            kpi2Number={kpi2Number}
            kpi2ShortDescription={kpi2ShortDescription}
            kpi2Icon={kpi2Icon}
            kpi3Number={kpi3Number}
            kpi3ShortDescription={kpi3ShortDescription}
            kpi3Icon={kpi3Icon}
            kpi4Number={kpi4Number}
            kpi4ShortDescription={kpi4ShortDescription}
            kpi4Icon={kpi4Icon}
          />
        )}

        <div className="sideWrapper">
          <div>
            {pillarsTitle && (
              <PillsList
                title={pillarsTitle}
                text1={pillarsItem1}
                text2={pillarsItem2}
                text3={pillarsItem3}
                text4={pillarsItem4}
                text5={pillarsItem5}
              />
            )}
          </div>
        </div>
      </Section>
      <BoxLayout
        handlePlayClick={handlePlayClick}
        videoIsPlaying={videoIsPlaying}
        media={<Media ref={videoRef} data={media} poster={thumbnail} />}
      />
      <Section hasSmallPadding>
        {contentSections1 &&
          R.map(
            node => (
              <ContentSection
                title={node.title}
                callToAction={node.callToAction}
                variation={node.variation}
                description={node.description}
                image={node.image}
                id={node.id}
                key={node.id}
                typeOfLink={node.typeOfLink}
                link={node.link}
                countryData={countryData}
                typeOfLink={node.typeOfLink}
                link={node.link}
              />
            ),
            contentSections1,
          )}
      </Section>
      <Section hasSmallPadding hasGreyBackground>
        {contentSections2 &&
          R.map(
            node => (
              <ContentSection
                title={node.title}
                callToAction={node.callToAction}
                variation={node.variation}
                description={node.description}
                image={node.image}
                id={node.id}
                key={node.id}
                typeOfLink={node.typeOfLink}
                link={node.link}
                countryData={countryData}
              />
            ),
            contentSections2,
          )}
      </Section>
      {contentSections3 && (
        <Section hasSmallPadding>
          {R.map(
            node => (
              <ContentSection
                title={node.title}
                callToAction={node.callToAction}
                variation={node.variation}
                description={node.description}
                image={node.image}
                id={node.id}
                key={node.id}
                link={node.link}
                typeOfLink={node.typeOfLink}
                countryData={countryData}
              />
            ),
            contentSections3,
          )}
        </Section>
      )}
    </>
  )
}

AboutUs.propTypes = aboutUsMainPropTypes

AboutUs.defaultProps = {
  callToAction: '',
  callToActionLinkedPage: shape({slug: ''}),
  contentSections1: null,
  contentSections2: null,
  contentSections3: null,
  imageOrVideo: null,
  kpi1Icon: '',
  kpi1Number: '',
  kpi1ShortDescription: '',
  kpi2Icon: '',
  kpi2Number: '',
  kpi2ShortDescription: '',
  kpi3Icon: '',
  kpi3Number: '',
  kpi3ShortDescription: '',
  kpi4Icon: '',
  kpi4Number: '',
  kpi4ShortDescription: '',
  metaDescription: shape({metaDescription: ''}),
  metaTitle: '',
  pageTitle: '',
  pillarsItem1: '',
  pillarsItem2: '',
  pillarsItem3: '',
  pillarsItem4: '',
  pillarsItem5: '',
  pillarsTitle: '',
  shortDescription: shape({shortDescription: ''}),
  surtitle: '',
}

export default AboutUs

export const pageQuery = graphql`
  query templateAboutUs(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulAboutUs(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      homepageTitle
      imageOrVideo {
        gatsbyImageData(placeholder: BLURRED)
        file {
          url
          contentType
        }
        title
        description
      }
      metaTitle
      pageTitle
      name
      shortDescription {
        shortDescription
      }
      callToAction
      callToActionLinkedPage {
        slug
      }
      kpi1Number
      kpi1ShortDescription
      kpi1Icon {
        file {
          url
          contentType
        }
        title
        description
      }
      kpi2Number
      kpi2ShortDescription
      kpi2Icon {
        file {
          url
        }
        title
        description
      }
      kpi3Number
      kpi3ShortDescription
      kpi3Icon {
        file {
          url
        }
        title
        description
      }
      kpi4Number
      kpi4ShortDescription
      kpi4Icon {
        file {
          url
        }
        title
        description
      }
      metaDescription {
        metaDescription
      }
      pillarsItem1
      pillarsItem2
      pillarsItem3
      pillarsItem4
      pillarsItem5
      pillarsTitle
      media {
        file {
          url
          fileName
          contentType
        }
      }
      thumbnail {
        file {
          url
          fileName
          contentType
        }
      }
      surtitle
      contentSections1 {
        ...contentSectionFields
      }
      contentSections2 {
        ...contentSectionFields
      }
      contentSections3 {
        ...contentSectionFields
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
