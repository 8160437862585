import * as R from 'ramda'
import React from 'react'

import {kpisListPropTypes} from 'helpers/propTypes'
import Description from 'components/UI/Description'
import Title from 'components/UI/Title'
import useStyles from './styles'

const KpisList = ({
  kpi1Number,
  kpi1ShortDescription,
  kpi1Icon,
  kpi2Number,
  kpi2ShortDescription,
  kpi2Icon,
  kpi3Number,
  kpi3ShortDescription,
  kpi3Icon,
  kpi4Number,
  kpi4ShortDescription,
  kpi4Icon,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)

  const kpis = [
    {number: kpi1Number, description: kpi1ShortDescription, icon: kpi1Icon},
    {number: kpi2Number, description: kpi2ShortDescription, icon: kpi2Icon},
    {number: kpi3Number, description: kpi3ShortDescription, icon: kpi3Icon},
    {number: kpi4Number, description: kpi4ShortDescription, icon: kpi4Icon},
  ]

  const renderImage = icon => (
    <div className={classes.icon}>
      <img src={icon.file.url} alt={icon.description} loading="lazy" />
    </div>
  )
  const renderText = node => (
    <>
      <Title variant="h3" type="subTitle" isCentered>
        {node.number}
      </Title>
      <Description isCentered>{node.description}</Description>
    </>
  )

  return (
    <>
      <div className={classes.wrapper}>
        {mapIndexed(
          (node, index) =>
            node && (
              <div className={classes.card} key={index}>
                {renderImage(node.icon)}
                {renderText(node)}
              </div>
            ),
          kpis,
        )}
      </div>
    </>
  )
}

KpisList.propTypes = kpisListPropTypes

KpisList.defaultProps = {
  kpi1Icon: null,
  kpi1Number: '',
  kpi1ShortDescription: '',
  kpi2Icon: null,
  kpi2Number: '',
  kpi2ShortDescription: '',
  kpi3Icon: null,
  kpi3Number: '',
  kpi3ShortDescription: '',
  kpi4Icon: null,
  kpi4Number: '',
  kpi4ShortDescription: '',
}

export default KpisList
