import * as R from 'ramda'
import {string} from 'prop-types'
import React from 'react'

import Title from 'components/UI/Title'

import useStyles from './styles'

const PillsList = ({
  title,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const pills = [text1, text2, text3, text4, text5, text6, text7]

  return (
    <div className={classes.wrapper}>
      <Title isCentered variant="h2">
        {title}
      </Title>
      <ul className={classes.subWrapper}>
        {mapIndexed(
          (node, index) =>
            node &&
            node.length !== 0 && (
              <li className={classes.pills} key={index}>
                {node}
              </li>
            ),
          pills,
        )}
      </ul>
    </div>
  )
}

PillsList.propTypes = {
  text1: string,
  text2: string,
  text3: string,
  text4: string,
  text5: string,
  text6: string,
  text7: string,
  title: string,
}
PillsList.defaultProps = {
  text1: '',
  text2: '',
  text3: '',
  text4: '',
  text5: '',
  text6: '',
  text7: '',
  title: '',
}

export default PillsList
